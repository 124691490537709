import { APICallUrl } from "../Components/Constant";
import { toast } from "react-toastify";
// import axios from 'axios';
//
// export const getAllUsers =
//     (url = '', type) =>
//         async (dispatch) => {
//             try {
//                 const {data} = await axios.get(`${process.env.API_URL}${url}`);
//                 dispatch({
//                     type,
//                     payload: data,
//                 });
//             } catch (error) {
//                 console.log(error);
//             }
//         };

export const sendFeedback = async (feedbackData) => {
    try {
        const response = await fetch(`${APICallUrl}/api/feedback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(feedbackData)
        })
        const { message } = await response.json();
        toast.success(message)
    } catch(err) {
        console.error(err)
    }
};

export const sortByCategory = async (categoryIds, dateTo, dateFrom) => {

    const tags = categoryIds.map(id => `&tag_ids[]=${id}`).join('');
    const response = await fetch(`https://api.koaedi.com/api/v1/posts?per_page=9&type=event&date_to=${dateFrom}${tags}&date_from=${dateTo}`);
    const res = await response.json();

    return res;
}

export const getNoteData = async (loginToken) => {

    return fetch('https://api.koaedi.com/api/get-card-v2', {
        method: 'GET',
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${loginToken.token}`
        }
    });
}
export const checkLoginStatus = async (loginToken) => {
    // console.log("Token sent to API:", loginToken);

    try {
        const response = await fetch('https://api.koaedi.com/api/v1/check-login', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${loginToken.token}`,
                "Content-Type": "application/json;charset=UTF-8",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return { success: true, data }; // Ensure to return an object with `success` and `data`

    } catch (error) {
        console.error("Error checking login status:", error);
        return { success: false, message: "Failed to verify authentication" }; // Return a failure object with `success` and `message`
    }
};


export const sendNote = async (note, loginToken) => {
    console.log(loginToken)
    const sendedData = {
        note
    }
    return await fetch('https://api.koaedi.com/api/add-note', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${loginToken.token}`
        },
        body: JSON.stringify(sendedData)
    })
};

export const sendClickAnalytic = async (customerId, slug) => {
    return await fetch(`https://api.koaedi.com/api/v1/posts/${slug}/click`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            customer_id: customerId
        })
    })
};

export const getMeData = async (loginToken) => {
    const response = await fetch(APICallUrl + '/api/v1/me', {
        method: 'GET',
        headers: {
            'Content-Type': "application/json;charset=UTF-8",
            Authorization: `Bearer ${loginToken.token}`
        }
    });

    const resData = await response.json();

    return resData.data;
}