import { Component } from "react";

class ErrorBoundry extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });

        console.error('error -> ', error)
        console.error('errorInfo -> ', errorInfo)
    }

    render() {
        if(this.state.hasError) {
            return <div className='err__wrapper'>
                <span>Something went wrong!</span>
            </div>
        }

        return this.props.children
    }
}

export default ErrorBoundry